<div [ngClass]="fullSize ? 'container-full' : 'container'">
    <div class=WordSection1>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><a
                name="_Toc116557088"><b><span style='font-size:14.0pt;font-family:"Times New Roman",serif'>CONDITIONS
GENERALES D’UTILISATION DE L’ESPACE CLIENT ALPES CONTRÔLES</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Applicables
à compter du 02/11/2023 – Veuillez lire attentivement ces conditions générales
et en conserver une copie.</span></b></p>

        <p class=MsoTocHeading>Table des matières</p>

        <p class=MsoToc1><a href="#_Toc149637633"><b>1.&nbsp;&nbsp;&nbsp; Dispositions
            générales et acceptation des conditions générales d’utilisation</b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc1><a href="#_Toc149637634"><b>2.&nbsp;&nbsp;&nbsp; Définitions</b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc1><a href="#_Toc149637635"><b>3.&nbsp;&nbsp;&nbsp; Description
            des Services</b><span style='color:windowtext;display:none;text-decoration:
none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637636"><b>3.1 Présentation Générale</b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637637"><b>3.2 Organisation de l’Espace
            Client</b><span style='color:windowtext;display:none;text-decoration:none'> </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc3><a href="#_Toc149637638"><b>3.2.1 Mise à disposition des
            Documents</b><span style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc3><a href="#_Toc149637639"><b>3.2.2 Statuts liés aux Documents
            techniques</b><span style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc3><a href="#_Toc149637640"><b>3.2.3 Extraction des anomalies
            (limitée au domaine Vérification des installations ou des équipements
            techniques en exploitation)</b><span style='color:windowtext;display:none;
text-decoration:none'> </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637641"><b>3.3 Diffusions des Documents</b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc1><a href="#_Toc149637642"><b>4.&nbsp;&nbsp;&nbsp; Création du
            Compte Utilisateur</b><span style='color:windowtext;display:none;text-decoration:
none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637643"><b>4.1&nbsp; Modalités d’inscription</b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637644"><b>4.2&nbsp; Identifiants personnels</b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637645"><b>4.3&nbsp; Suspension ou
            suppression du Compte Utilisateur</b><span style='color:windowtext;display:
none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc3><a href="#_Toc149637646"><b>4.3.1 Suppression du Compte
            Utilisateur à son initiative</b><span style='color:windowtext;display:none;
text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc3><a href="#_Toc149637647"><b>4.3.2&nbsp;&nbsp;&nbsp;&nbsp;
            Suspension/Suppression du Compte Utilisateur à l’initiative de la société
            BUREAU <span style='text-transform:uppercase'>Alpes Contrôles</span></b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc1><a href="#_Toc149637648"><b>5.&nbsp;&nbsp;&nbsp; Modalité
            d’Utilisation</b><span style='color:windowtext;display:none;text-decoration:
none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc1><a href="#_Toc149637649"><b>6.&nbsp;&nbsp;&nbsp; Propriété
            intellectuelle</b><span style='color:windowtext;display:none;text-decoration:
none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637650"><b>6.1&nbsp; Relative à l’Espace
            Client</b><span style='color:windowtext;display:none;text-decoration:none'> </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637651"><b>6.2&nbsp; Relative aux Documents
            techniques</b><span style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc1><a href="#_Toc149637652"><b>7.&nbsp;&nbsp;&nbsp; Garantie-
            Responsabilité</b><span style='color:windowtext;display:none;text-decoration:
none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637653"><b>7.1 Garanties - Responsabilité de
            la société BUREAU ALPES CONTRÔLES</b><span style='color:windowtext;display:
none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637654"><b>7.2 Garanties - Responsabilités de
            l'Utilisateur</b><span style='color:windowtext;display:none;text-decoration:
none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc1><a href="#_Toc149637655"><b>8.&nbsp;&nbsp;&nbsp; Protection
            des données à caractère personnel</b><span style='color:windowtext;display:
none;text-decoration:none'> </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637656"><b>8.1 Principes généraux</b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637657"><b>8.2 Finalités et bases juridiques
            des Données</b><span style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637658"><b>8.3 Durée de conservation des
            Données</b><span style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637659"><b>8.4 Politique de Cookies</b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc2><a href="#_Toc149637660"><b>8.5 Droits d'opposition, de
            rectification, de limitation et de suppression des Données par l'Utilisateur</b><span
                style='color:windowtext;display:none;text-decoration:none'>  </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc1><a href="#_Toc149637661"><b>9.&nbsp;&nbsp;&nbsp; Divers</b><span
                style='color:windowtext;display:none;text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoToc1><a href="#_Toc149637662"><b>10.&nbsp; Droit applicable et
            juridiction compétente</b><span style='color:windowtext;display:none;
text-decoration:none'>. </span><span
                style='color:windowtext;display:none;text-decoration:none'>1</span></a></p>

        <p class=MsoNormal>&nbsp;</p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637633"></a><a name="_Toc126835530"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>1.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Dispositions
générales et acceptation des conditions générales d’utilisation</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Espace
Client proposé par la société BUREAU ALPES CONTRÔLES, société par action
simplifiée, au capital de 2&nbsp;000&nbsp;000 €, inscrite au Registre du
Commerce et des sociétés d’ANNECY sous le numéro 351&nbsp;812&nbsp;698, dont le
siège social est situé 3 bis Impasse des Prairies – Annecy le Vieux – 74940
ANNECY, (ci-après, la «&nbsp;<b>société BUREAU ALPES CONTRÔLES</b>&nbsp;»), a
pour objet de proposer aux Utilisateurs, différents Services dont la mise à
disposition de différents documents réalisés par la société <span
                    style='text-transform:uppercase'>Bureau Alpes ContrÖles</span> dans le cadre de
son Contrat avec le Client.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
présentes conditions générales d’utilisation (ci-après, les «&nbsp;<b>CGU</b>&nbsp;»)
régissent l’accès et l’utilisation de l’Espace Client édité et mis à
disposition de l’Utilisateur par la société BUREAU ALPES CONTRÔLES. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Le fait
d'accéder et de naviguer au sein de l’Espace Client constitue de la part de
l'Utilisateur une acceptation sans réserve des CGU. Si ces dernières sont mises
à jour régulièrement, l'Utilisateur sera invité à les accepter de nouveau. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>IMPORTANT –
A L'ATTENTION DES UTILISATEURS</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L'ACCES ET
L'UTILISATION DE L’ESPACE CLIENT IMPLIQUENT L'ACCEPTATION EXPRESSE, PREALABLE,
PLEINE ET ENTIERE PAR L'UTILISATEUR DES PRESENTES CONDITIONS GENERALES
D'UTILISATION.</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>EN CREANT
UN COMPTE, L'UTILISATEUR RECONNAIT QU'IL ACCEPTE LES PRESENTES CONDITIONS
GENERALES D'UTILISATION ET COCHERA LA CASE CORRESPONDANTE AVANT LA CREATION DE
SON COMPTE.</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Cette
acceptation sera réputée avoir la même valeur qu’une signature manuscrite de la
part de l’Utilisateur.&nbsp; </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637634"></a><a name="_Toc126835531"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>2.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Définitions</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Dans les
présentes CGU, les termes commençant par une majuscule ont la signification
indiquée ci-dessous, qu’ils soient au singulier ou au pluriel.</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«&nbsp;<b>Administrateur
Client</b>&nbsp;»&nbsp;: Utilisateur qui visualise tous les menus et peut créer
des comptes Administrateurs Client et/ou Utilisateurs Clients en leur associant
la visualisation des menus qu’il souhaite&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b><span style='font-size:12.0pt;font-family:"Arial",sans-serif'>&nbsp;«
C<span style='color:black'>GU »</span></span></b><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'> : désigne les présentes conditions
générales d'utilisation de la société BUREAU ALPES CONTRÔLES opposables à toute
personne naviguant sur l’Espace Client et/ou souhaitant créer un Compte sur
l’Espace Client afin de bénéficier des Services proposés par l’Espace Client,
et que ladite personne doit accepter préalablement à la création de son Compte.
Les conditions générales de vente de la société BUREAU ALPES CONTROLES, jointes
au Contrat, priment, en cas de contradiction, sur les présentes CGU&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«&nbsp;<b>Client</b>&nbsp;»&nbsp;:
désigne toute personne physique ou morale ayant conclu un Contrat avec la
société BUREAU ALPES CONTRÔLES pour ses besoins tels que définis au </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif'>Contrat&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'><b>« Compte »</b></span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'> : désigne
le compte de l'Utilisateur sur l’Espace Client, accessible via un identifiant
ainsi que d'un mot de passe propres à l'Utilisateur&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:Symbol;color:black'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«&nbsp;<b>Contrat</b>&nbsp;»&nbsp;:
désigne le ou les contrat(s) de prestations de services conclu(s)entre les
Parties, en ce compris leurs éventuels avenants, dans le cadre duquel les
Documents et Documents techniques sont émis par la société BUREAU ALPES
CONTRÔLES;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«&nbsp;<b>Documents</b>&nbsp;»&nbsp;:
comprend notamment, mais sans s’y limiter, les Documents techniques ci-après
définis, les duplicata de </span><span style='font-size:12.0pt;font-family:
"Arial",sans-serif'>factures </span><span style='font-size:12.0pt;font-family:
"Arial",sans-serif'>et les Contrats </span><span style='font-size:14.0pt;
font-family:"Arial",sans-serif'>;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«&nbsp;<b>Documents
techniques</b>&nbsp;» ou «&nbsp;<b>Rapports</b>&nbsp;» : tous documents émis
par la société <span style='text-transform:uppercase'>Bureau Alpes Contrôles </span>dans
le cadre de l’exécution de son Contrat </span><span style='font-size:12.0pt;
font-family:"Arial",sans-serif'>avec le Client&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;«&nbsp;<b>Espace
Client </b>»&nbsp;: désigne le service en ligne de type extranet client
développé et mis à disposition par la société <span style='text-transform:uppercase'>Bureau
Alpes Contrôles</span> à l’attention de ses Clients. L’Espace Client est
accessible à l’URL suivante et/ou toute adresse qui viendrait s’y ajouter ou
s’y substituer&nbsp;: </span><a
                href="https://espace-client.alpes-controles.fr/login" target="_blank"><span
                style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;background:#F7F8FA'>https://espace-client.alpes-controles.fr/login</span></a>
        </p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Segoe UI",sans-serif;
color:blue;background:#F7F8FA'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;«&nbsp;<b>Informations
Confidentielles&nbsp;</b>»&nbsp;: </span><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>Sont considérées ci-après comme
Informations Confidentielles, notamment&nbsp;:</span></p>

        <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:36.0pt;margin-bottom:.0001pt;text-align:justify;text-indent:
-18.0pt'><span style='font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>toute
information nominative, technique, financière relative à un projet objet du 
Contrat entre les Parties,</span></p>

        <p class=MsoListParagraph style='margin-left:36.0pt;text-align:justify;
text-indent:-18.0pt'><span style='font-family:Symbol;color:black'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>toute
information relative aux prestations proposées par la société BUREAU ALPES
CONTROLES, notamment quant à leurs spécifications et leurs prix, </span></p>

        <p class=MsoListParagraph style='margin-left:36.0pt;text-align:justify;
text-indent:-18.0pt'><span style='font-family:Symbol;color:black'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>toute
information relative au contenu, forme, rédaction des Contrats, des conditions
spéciales, des conditions générales de vente et des présentes CGU de la société
BUREAU ALPES CONTROLES,</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:36.0pt;margin-bottom:.0001pt;text-align:justify;text-indent:
-18.0pt'><span style='font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>toute
information relative au contenu, aux fonctionnalités, à la structure de
l’Espace Client.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;color:black'>L’Utilisateur
s'interdit de divulguer toutes Informations Confidentielles pour le bénéfice de
tout tiers ou pour quelque fin que ce soit autre que dans le cadre de l’exécution
du Contrat avec la société BUREAU ALPES CONTROLES, sans le consentement écrit
préalable de la Partie qui a divulgué ces Informations Confidentielles.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;color:black'>Les obligations du
présent article demeurent pour toute la durée d’utilisation de l’Espace Client
par les Utilisateurs ainsi qu’à l’expiration ou à la résiliation du Contrat
pour un motif quelconque, pendant une durée de cinq ans.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;color:black'>La divulgation
d'Informations Confidentielles exigée par une autorité administrative, pénale
ou judiciaire n'est pas considérée comme une violation du présent article, à
condition toutefois que l’Utilisateur en informe immédiatement par écrit la
société BUREAU ALPES CONTROLES, sauf si la loi le lui interdit, et qu’il
coopère avec cette dernière, à ses frais, dans le but de prendre toute mesure
raisonnable pour éviter cette divulgation. En tout état de cause, l’Utilisateur
ne doit divulguer que la partie de l'Information Confidentielle qu’il est
légalement tenu de divulguer.</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«&nbsp;<b>Parties</b>&nbsp;»&nbsp;:
désigne le Client et la société BUREAU ALPES CONTRÔLES liés par le Contrat</span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif'>&nbsp;<span
                style='color:black'>;</span></span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«&nbsp;<b>Services</b>&nbsp;»&nbsp;:
désigne les services édités par la société BUREAU ALPES CONTRÔLES dans le cadre
de l’Espace Client et tels que décrits à l’Article 3 ci-après&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«<b>&nbsp;Super
Administrateur Alpes Contrôles&nbsp;</b>»&nbsp;: Ce rôle est affecté au
personnel informatique et personnes chargées de support de la société BUREAU
ALPES CONTRÔLES&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«&nbsp;<b>Utilisateur</b>&nbsp;»&nbsp;:
désigne toute personne physique ou morale, qui utilise l’Espace Client&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><s><span style='font-size:12.0pt;font-family:Symbol'>·</span></s><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif'>«<b>&nbsp;<span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Utilisateur Alpes Contrôles&nbsp;</span></b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>»&nbsp;: personnel de la société BUREAU ALPES CONTRÔLES
pouvant administrer les comptes de l’ensemble des Utilisateurs&nbsp;;</span></span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;«&nbsp;<b>Utilisateur
Client</b>&nbsp;»&nbsp;: Utilisateur qui visualise l’intégralité des menus à
l’exception du menu «&nbsp;Mes factures&nbsp;»&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>«&nbsp;<b>Utilisateur
Client Limité</b>&nbsp;»&nbsp;: Utilisateur qui visualise les menus en fonction
des droits qui lui ont été affectés par l’Administrateur Client. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal;text-autospace:
none'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal;text-autospace:
none'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637635"></a><a name="_Toc126835532"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>3.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Description des
Services</span></b></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637636"></a><a
                name="_Toc126835533"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>3.1
Présentation Générale</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Espace
Client est un espace personnel sécurisé accessible dans le cadre du Contrat
conclu entre le Client et la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Via cet
Espace Client, la société BUREAU ALPES CONTRÔLES propose à ses Utilisateurs la
mise à disposition des Documents techniques émis par les services de la société
BUREAU ALPES CONTRÔLES dans le cadre des missions confiées conformément au Contrat
entre cette dernière et le Client. Ces Documents techniques, en
fonction&nbsp;du domaine de la mission confiée, seront répartis dans l’Espace
Client en différents menus.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
honoraires négociés au Contrat comprennent l'accès du client à l'Espace Client
pendant une période de 2 ans à compter de la date de création du compte, sauf
conditions contraires convenues au Contrat.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’affichage
de l’exhaustivité des données et Documents du Client émis, y compris avant la
création de l’Espace Client, n'est pas garantie.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’ensemble
des caractéristiques des Services proposés peut varier dans le temps en
fonction de l’évolution des prestations proposées par la société BUREAU ALPES
CONTRÔLES et des dispositions législatives et réglementaires qui leurs sont
applicables.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Utilisateur
reste seul responsable de l’utilisation des Documents mis à sa disposition sur
l’Espace Client.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES ne pourra être tenue pour responsable des préjudices que
causerait un quelconque Utilisateur de l’Espace Client en utilisant des
informations issues de cet Espace Client sécurisé à d’autres fins que celles
auxquelles elles sont destinées dans le cadre du Contrat avec la société BUREAU
ALPES CONTRÔLES. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637637"></a><a
                name="_Toc126835534"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>3.2
Organisation de l’Espace Client</span></b></a></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637638"></a><a
                name="_Toc126835535"><b><span style='font-size:13.5pt;font-family:"Times New Roman",serif'>3.2.1
Mise à disposition des Documents</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
Documents du Client sont répartis en différents menus dans l’Espace Client, à
savoir&nbsp;: </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Menu
«&nbsp;Mes Opérations&nbsp;» : </span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
Documents techniques sont disponibles sur l’Espace Client a minima jusqu'à
l’issue d’une période de deux (2) ans après la fin des interventions de la
société BUREAU ALPES CONTRÔLES effectuée dans le cadre du Contrat.</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
domaines (Marchés)&nbsp;de la société BUREAU ALPES CONTRÔLES concernés sont :
Certificats d'Economie d'Energie, Contrôle Technique, Coordination Sécurité et
Protection de la Santé, Avis Après Sinistre, Autorisation Enregistrement
Déclaration des ICPE, Évènementiel, Sites et Sols Pollués, Missions
spécifiques, Missions HSST hors-SPS.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Menu
«&nbsp;Mon Patrimoine&nbsp;» : </span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
Documents techniques sont disponibles a minima jusqu'à l’issue d’une période de
deux (2)  ans après leur émission, et jusqu'aux prochains Rapports périodique. </span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
domaines (Marchés)&nbsp;de la société BUREAU ALPES CONTRÔLES concernés sont :
Certification Produits et Services, Diagnostic Immobilier, Inspections des
Ouvrages d'Art, Environnement, Vérification des installations ou des
équipements techniques en exploitation.</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>Menu «&nbsp; Mes Contrats&nbsp;»&nbsp;: </span></p>

        <p class=MsoListParagraph style='margin-left:18.0pt;text-align:justify;
text-indent:-18.0pt'><span style='font-family:"Arial",sans-serif;color:black'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>Les Contrats conclus entre
le Client et la société BUREAU ALPES CONTROLES et/ou les bons de commande liés
sont disponibles pour les mêmes durées que les Documents techniques évoqués
précédemment selon leur catégorisation dans «&nbsp;Mes Opérations&nbsp;» et
«&nbsp;Mon Patrimoine&nbsp;».</span></p>

        <p class=MsoListParagraph style='margin-left:18.0pt;text-align:justify;
text-indent:-18.0pt'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>Les offres de Contrats émises
par la société BUREAU ALPES CONTROLES en attente de signature par le Client
sont également disponibles durant leur période de validité.</span></p>

        <p class=MsoListParagraph style='margin-left:18.0pt;text-align:justify;
text-indent:-18.0pt'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>Les Contrats refusés ou
annulés par l’une des deux Parties ne sont pas disponibles. </span></p>

        <p class=MsoListParagraph style='margin-left:18.0pt;text-align:justify;
text-indent:-18.0pt'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>Les Contrats cadres ou de
référencement conclus entre le Client et la société BUREAU ALPES CONTROLES ne
sont pas disponibles.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Menu
«&nbsp;Mes Factures&nbsp;»&nbsp;: </span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
duplicatas des factures du Client sont disponibles, après leur émission, pour
les mêmes durées que les Documents techniques évoquées précédemment selon leur
catégorisation dans «&nbsp;Mes Opérations&nbsp;» et «&nbsp;Mon
Patrimoine&nbsp;».</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
informations et Documents techniques affichés dans l’Espace Client sont ceux
définis dans les bases de données de la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Espace Client
ne permettant l’affichage des Documents que pour la durée déterminée précitée,
il appartient au Client de veiller à leur archivage en les téléchargeant.</span></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637639"></a><a
                name="_Toc126835536"><b><span style='font-size:13.5pt;font-family:"Times New Roman",serif'>3.2.2
Statuts liés aux Documents techniques</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Des
pictogrammes peuvent mettre en valeur des conclusions positives ou négatives
d’un Document technique lorsque les aspects réglementaires peuvent le
permettre.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES rappelle que ces pictogrammes ne dispensent en aucun cas
le Client de consulter et lire attentivement l’ensemble des Documents
techniques. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>En aucun
cas, il ne pourra être reproché à la société BUREAU ALPES CONTRÔLES de ne pas
avoir attiré l’attention du Client sur la seule base de l’affichage du
pictogramme associé, seul le contenu du Document technique concerné faisant foi
entre les Parties.</span></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637640"></a><a
                name="_Toc126835537"><b><span style='font-size:13.5pt;font-family:"Times New Roman",serif'>3.2.3
Extraction des anomalies (limitée au domaine Vérification des installations ou
des équipements techniques en exploitation)</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Espace
Client permet au Client de réaliser une extraction des anomalies au format xls
pour certains Documents techniques liés exclusivement au domaine de
«&nbsp;Vérification des installations ou des équipements techniques en
exploitation&nbsp;».</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La
structuration informatique de la société BUREAU ALPES CONTRÔLES ne permet
néanmoins pas de garantir l’exhaustivité des données de cette extraction.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
Documents techniques diffusés par la société BUREAU ALPES CONTRÔLES restent
donc les seuls documents faisant foi à titre de preuve entre les Parties.</span></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637641"></a><a
                name="_Toc126835538"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>3.3
Diffusions des Documents</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Espace
Client permet l’affichage des Documents déjà envoyés par la société BUREAU
ALPES CONTRÔLES au Client par courriel, courrier ou fax.</span></p>

        <p class=MsoNormal><span class=Corpsdutexte21><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>Dans tous les cas, seuls les documents au format PDF diffusés
par e-mail ou courrier, par la société BUREAU ALPES CONTROLES auront valeur de
preuve de transmission entre les Parties et vis-à-vis de tous tiers. </span></span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Sur demande
écrite du Client, les Documents peuvent être uniquement déposés dans l’Espace
Client et ne plus être transmis selon les modes de diffusions précités&nbsp;:
il lui appartiendra dans ce cas de consulter régulièrement son Espace Client. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Il est
rappelé que l’exhaustivité des données et Documents du Client disponibles dans
l’Espace Client n’est pas garantie par la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637642"></a><a name="_Toc126835539"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>4.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Création du Compte
Utilisateur</span></b><b><span style='font-size:24.0pt;font-family:"Times New Roman",serif'>
&nbsp;</span></b></p>

        <p class=MsoNormal style='margin-left:18.0pt;line-height:normal'><a
                name="_Toc149637643"></a><a name="_Toc126835540"><b><span style='font-size:
18.0pt;font-family:"Times New Roman",serif'>4.1</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp; </span></b><b><span
                style='font-size:18.0pt;font-family:"Times New Roman",serif'>Modalités
d’inscription</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Espace
Client est exclusivement réservé aux Clients de la société BUREAU ALPES
CONTRÔLES. </span></p>

        <p class=MsoNormal style='margin-bottom:6.0pt;line-height:13.7pt'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Afin de
pouvoir bénéficier du Service, l’Utilisateur Administrateur Client devra
préalablement remplir les conditions suivantes&nbsp;:</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;
margin-left:36.0pt;text-indent:-18.0pt;line-height:13.7pt'><span
                style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black'>-</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Disposer
d’un Contrat de prestation avec la société BUREAU ALPES CONTRÔLES&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Faire une
demande auprès du contact administratif de la société BUREAU ALPES CONTRÔLES
communiqué lors de la signature du Contrat de prestation susmentionné;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Communiquer
une adresse e-mail valide aux services de la société BUREAU ALPES CONTRÔLES
lors de la souscription dudit Contrat&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Disposer
d’une connexion internet.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Afin de
confirmer la création d’un Compte et vérifier l’identité de l’Utilisateur
Administrateur Client, un e-mail avec un code de confirmation sera envoyé à
l’adresse e-mail renseignée par ce dernier. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Cette
adresse e-mail correspondra à son identifiant lui permettant de se connecter à
l’Espace Client. L’Utilisateur Administrateur Client devra ensuite créer son
mot de passe afin d’activer son Compte principal. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Lors de la
souscription d’un Contrat, le Client s’engage à ne donner un accès Utilisateur
Administrateur Client qu’aux personnes appartenant à son entité juridique et
possédant une adresse e-mail professionnelle en lien avec cette dernière.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Utilisateur
Administrateur Client ne pourra en aucun cas être une tierce partie au Contrat.
</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Utilisateur
Administrateur Client pourra ensuite créer des comptes Utilisateur Client
Limité sous sa propre responsabilité. Il s’engage à ne pas créer de compte
Utilisateur Client Limité pour une tierce partie au Contrat sans l’autorisation
préalable et écrite de la société BUREAU ALPES CONTRÔLES. Dans cette dernière
hypothèse, ou dans l’hypothèse d’un accès d’un tiers par négligence du Client
et/ou de l’Utilisateur, ces derniers restent responsables de l’usage de
l’Espace Client par lesdits tiers. En aucun cas la responsabilité de la société
BUREAU ALPES CONTRÔLES ne pourra être recherchée à ce titre.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La
procédure de création de Compte par les Utilisateurs est identique à celle
précédemment décrite ci-dessus.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L'Utilisateur
garantit que la création d'un Compte ne porte pas atteinte aux droits des tiers
(utilisation du nom patronymique, de la marque d’autrui, ou d’œuvres protégées
par le droit d’auteur et/ou le droit voisin). La société BUREAU ALPES CONTRÔLES
ne saurait être tenue responsable des erreurs que l'Utilisateur aurait pu
commettre dans la communication de ses données et renseignements et se réserve
la faculté de refuser et/ou d’exiger la correction des informations
communiquées. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Par
ailleurs, la société BUREAU ALPES CONTRÔLES ne pourra pas être tenue pour
responsable de tous dommages directs ou indirects subis par l'Utilisateur en
cas d'utilisation frauduleuse du Compte par un tiers, sauf si cette utilisation
est la conséquence d'une faute de la société BUREAU ALPES CONTRÔLES. </span></p>

        <p class=MsoNormal style='margin-left:18.0pt;line-height:normal'><a
                name="_Toc149637644"></a><a name="_Toc126835541"><b><span style='font-size:
18.0pt;font-family:"Times New Roman",serif'>4.2</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp; </span></b><b><span
                style='font-size:18.0pt;font-family:"Times New Roman",serif'>Identifiants
personnels</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L'Utilisateur
se connecte à l’Espace Client en renseignant son adresse e-mail et son mot de
passe enregistrés lors de la création de son Compte (ci-après, les « <b>Identifiants</b>
»).</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Ces
Identifiants sont personnels et confidentiels, ils ne doivent en aucun cas être
divulgués à des tiers, de quelle que manière que ce soit.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
Identifiants sont conservés sous la seule responsabilité de l'Utilisateur.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Toute
connexion à l’Espace Client via l’utilisation conjuguée des logins d’un
Utilisateur sera réputée être effectuée par le titulaire de ces logins.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>En cas de
changement d’adresse e-mail, l’Utilisateur devra en informer les Utilisateurs
Alpes Contrôles afin qu’une mise à jour soit effectuée au niveau de ces
identifiants.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Sans
préjudice des dispositions de l’article 34 de la loi du 6 janvier 1978
relatives à l’obligation du responsable de traitement de données à caractère
personnel de prendre toutes précautions utiles pour préserver la sécurité des
données et notamment empêcher que des tiers non autorisés y aient accès, chaque
Utilisateur est responsable de toute utilisation fautive du Compte Utilisateur,
toute connexion ou transmission fautive de données effectuée en utilisant
l’Espace Client.</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;line-height:normal'><a
                name="_Toc149637645"></a><a name="_Toc126835542"><b><span style='font-size:
18.0pt;font-family:"Times New Roman",serif'>4.3</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp; </span></b><b><span
                style='font-size:18.0pt;font-family:"Times New Roman",serif'>Suspension ou
suppression du Compte Utilisateur</span></b></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637646"></a><a
                name="_Toc126835543"><b><span style='font-size:13.5pt;font-family:"Times New Roman",serif'>4.3.1
Suppression du Compte Utilisateur à son initiative</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Chaque
Utilisateur, ou toute personne représentant officiellement la société à
laquelle appartient un Utilisateur de l’Espace Client peut supprimer son/un
Compte Utilisateur à tout moment en en faisant la demande auprès des services
de la société BUREAU ALPES CONTRÔLES par e-mail à&nbsp;: </span><a
                href="mailto:support-espace-client@alpes-controles.fr" target="_blank"><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black;background:
white'>support-espace-client&#64;alpes-controles.fr</span></a><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'> .</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Dans le cas
où un Utilisateur de l’Espace Client quitte le Client qui l’emploie, il
appartient au représentant du Client de procéder aux démarches de suppression
du Compte personnel de l’Espace Client de son collaborateur sortant en
adressant un e-mail à l’adresse mentionnée ci-dessus. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>De la même
manière, dans le cas où un Utilisateur Administrateur Client a créé, après
autorisation préalable écrite de la société BUREAU ALPES CONTRÔLES, un compte
Utilisateur Client Limité pour une tierce partie au Contrat, il lui appartient
de procéder aux démarches de suppression du Compte personnel de l’Espace Client
de ce tiers dans les cas où il ne souhaite plus que ce dernier ait les accès,
également par e-mail à l’adresse mentionnée ci-dessus. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Pour
faciliter les démarches et notamment accélérer le délai de traitement par la
société BUREAU ALPES CONTRÔLES, lors d’une demande de suppression d’un Compte
Utilisateur, le demandeur est invité à&nbsp;: </span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Mentionner
clairement ses noms / prénoms / coordonnées&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Indiquer le
motif de la demande de suppression d’un Compte Utilisateur</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES ne pourra être tenue pour responsable de l’utilisation
de l’Espace Client par le collaborateur du Client ayant quitté cette dernière
et pour lequel aucune demande de résiliation ne lui aura été adressée. </span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-indent:-36.0pt;line-height:
normal'><a name="_Toc149637647"></a><a name="_Toc126835544"><b><span
                style='font-size:13.5pt;font-family:"Times New Roman",serif'>4.3.2</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><span style='font-size:13.5pt;font-family:"Times New Roman",serif'>Suspension/Suppression
du Compte Utilisateur à l’initiative de la société BUREAU Alpes Contrôles</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>En cas de
manquement grave à l’une des obligations de l’Utilisateur au titre des CGU ou
en cas de refus d’acceptation des CGU, la société BUREAU ALPES CONTRÔLES se
réserve le droit d’adresser un e-mail d’avertissement (expliquant le motif de
suspension ou de clôture) à l’Utilisateur et/ou, si la société BUREAU ALPES
CONTRÔLES n’est pas en mesure de contacter l’Utilisateur du fait de
l’invalidité de l’adresse e-mail, de suspendre temporairement et/ou de clôturer
l’accès et l’utilisation du Compte Utilisateur, sans autre formalité et sans
dédommagement. Ces dispositions sont sans préjudice (i) de tous dommages et
intérêts auxquels la société BUREAU ALPES CONTRÔLES pourrait prétendre et (ii)
de toutes poursuites à l’initiative de la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:normal'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>Plus particulièrement, en cas d’inactivité de connexion, de
non-respect de la confidentialité des données de l’Espace Client, de situations
d’impayés ou de rupture de Contrat pour quelque motif que ce soit, la société
BUREAU ALPES CONTRÔLES pourra décider unilatéralement de résilier l’accès à
l’Espace Client d’un Utilisateur. </span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:normal'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>La société BUREAU ALPES CONTRÔLES informera l’Utilisateur par
e-mail de cette résiliation.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637648"></a><a name="_Toc126835545"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>5.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Modalité
d’Utilisation</span></b><b><span style='font-size:24.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;
</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>5.1</span></b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'> L'accès à
l’Espace Client peut se faire à partir d'un ordinateur, d'une tablette
informatique, d'un téléphone portable ou de tout matériel informatique
permettant d'accéder à Internet. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Utilisateur
aura un accès à l’Espace Client pour une durée déterminée selon les conditions
définies au paragraphe 3.2.1. Cette durée court à compter de son inscription et
de l’acceptation des présentes CGU et tant que des Documents sont affichés dans
l’Espace Client. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Pour
renforcer la protection des données à caractère personnel de l’Utilisateur, la
durée de chaque session est de 4 heures par défaut. Au-delà, l’Utilisateur
devra ressaisir ses identifiants. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Afin de
garantir la sécurité de l’Espace Client, il pourra être demandé à l’Utilisateur
de modifier son mot de passe ou de réaliser une double authentification.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>5.2</span></b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'> La société
BUREAU ALPES CONTRÔLES fait ses meilleurs efforts pour assurer la disponibilité
de l’Espace Client. Toutefois, l'Utilisateur est informé que la disponibilité
de l’Espace Client est soumise à une simple obligation de moyens et il déclare
accepter les caractéristiques et les limites de l'internet et reconnaît en
particulier :</span></p>

        <ul type=disc>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>Être conscient que
     les performances de l’Espace Client dépendent du type de connexion choisie
     et du niveau de sollicitation des serveurs informatiques sur lesquels est
     hébergé l’Espace Client, étant entendu que la connexion à l’Espace Client
     ne peut se faire que par le biais d'une connexion internet ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>Avoir connaissance
     de la nature du réseau internet et en particulier de ses performances
     techniques et des temps de réponse pour consulter, interroger ou
     transférer des informations ou des contenus ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>Qu’il lui
     appartient de prendre toutes les mesures appropriées de nature à protéger
     ses propres données et/ou matériel pour la contamination par d'éventuels
     virus informatiques ou tentatives d'intrusion susceptibles de nuire au bon
     fonctionnement ou d'endommager un matériel informatique ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>Que l’Espace
     Client peut ne pas être accessible selon le support et/ou le matériel
     utilisé par l'Utilisateur ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>Que certains liens
     accessibles sur l’Espace Client peuvent ne pas être disponibles en cas
     d'indisponibilité des pages et/ou sites internet vers lesquels les liens
     renvoient.</span></li>
        </ul>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>En conséquence,
la société BUREAU ALPES CONTRÔLES ne pourra en aucune manière être tenue
responsable notamment des difficultés ou de ralentissements d'accès à l’Espace
Client, du non-acheminement de messages, de la non-publication d'alertes…</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>5.3</span></b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'> La société
BUREAU ALPES CONTRÔLES se réserve la possibilité de modifier ou d'interrompre,
temporairement ou de façon permanente, l'accessibilité à l’Espace Client,
notamment mais non limitativement pour des raisons de maintenance, de mises à
jour ou d'améliorations ou, pour en faire évoluer le contenu et/ou la
présentation et ce, sans préavis ni indemnités quelconques.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES informera autant que possible l'Utilisateur de toute
indisponibilité programmée pour les raisons non limitatives exposées ci-dessus.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>5.4</span></b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'> L’Espace
Client peut contenir des liens vers des sites internet ou des ressources tiers,
qui peuvent être soumis à des conditions de services (CGV, CGS, CGU…) et à des
pratiques en matière de protection des données à caractère personnel différentes
de celles de la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES ne saurait être tenue pour responsable de la
disponibilité ou de l'exactitude de ces sites internet ou ressources tiers, ou
du contenu, des produits ou des services disponibles auprès de ceux-ci, les
liens vers ces sites internet et ressources tiers ne pouvant être interprétés
comme une approbation de la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637649"></a><a name="_Toc126835546"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>6.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Propriété
intellectuelle</span></b></p>

        <p class=MsoNormal style='margin-left:18.0pt;line-height:normal'><a
                name="_Toc149637650"></a><a name="_Toc126835547"><b><span style='font-size:
18.0pt;font-family:"Times New Roman",serif'>6.1</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp; </span></b><b><span
                style='font-size:18.0pt;font-family:"Times New Roman",serif'>Relative à
l’Espace Client</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Espace
Client, son contenu à l’exception des Documents techniques, ses
fonctionnalités, son arborescence, sa structure et la solution sont protégés
par des droits de propriété intellectuelle et/ou industrielle appartenant
exclusivement à la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L'utilisation
de tout ou partie de l’Espace Client n'emporte aucune cession de ces droits au
bénéfice de l'Utilisateur. L'Utilisateur se voit uniquement concéder, pour
toute la durée de sa connexion, une licence non exclusive, révocable et
incessible d'utilisation de l’Espace Client dans la limite de ce qui est
expressément prévu dans les présentes CGU et selon les conditions tarifaires
évoquées aux Contrats.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Toute
reproduction même partielle est subordonnée à l’autorisation préalable et
écrite de la société BUREAU ALPES CONTRÔLES. Tout lien hypertexte dirigé vers
une autre page que la page d'accueil de l’Espace Client est soumis à
l'autorisation préalable et écrite de la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L'Utilisateur
s'engage à ne reproduire aucun des éléments de l’Espace Client. Toute
utilisation contraire de tout ou partie de l’Espace Client ou de l'un
quelconque de ses éléments constituerait une contrefaçon susceptible
d'entraîner des poursuites civiles et/ou pénales et d'exposer l'Utilisateur
contrevenant aux peines applicables.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Il en est
de même pour les différents logos ou marques qui apparaissent sur l’Espace
Client, lesquels ne peuvent être utilisés sans l'autorisation écrite et
préalable de la société BUREAU ALPES CONTRÔLES. L'Utilisateur s'interdit tout
agissement et/ou tout acte pouvant porter atteinte directement ou indirectement
à ces droits et d'utiliser à des fins commerciales la technologie ou le
savoir-faire de l’Espace Client pour réaliser un site internet ou une
application informatique de quelle que nature que ce soit et/ou proposer des
services similaires ou susceptibles de concurrencer la société BUREAU ALPES
CONTRÔLES.</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;line-height:normal'><a
                name="_Toc149637651"></a><a name="_Toc126835548"><b><span style='font-size:
18.0pt;font-family:"Times New Roman",serif'>6.2</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp; </span></b><b><span
                style='font-size:18.0pt;font-family:"Times New Roman",serif'>Relative aux
Documents techniques</span></b></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:normal;text-autospace:none'><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>Toute mission de la société BUREAU
ALPES CONTRÔLES fait l'objet d'un ou plusieurs Documents techniques écrits ou
électroniques. Ce(s) Document(s) techniques est (sont) et demeure(nt) la
propriété du Client.</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;margin-bottom:.0001pt;text-align:justify;line-height:normal;
text-autospace:none'><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:normal;text-autospace:none'><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>Il ne peut être fait état,
vis-à-vis de tiers, des avis ou résultats émis par la société BUREAU ALPES
CONTRÔLES, que par publication ou communication &quot;in extenso&quot;. Il ne
peut non plus être fait état à titre publicitaire, de l'intervention de la
société BUREAU ALPES CONTRÔLES, sans avoir recueilli, au préalable, l'accord de
celle-ci sur le principe et le libellé de ladite publicité.</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:normal;text-autospace:none'><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:normal;text-autospace:none'><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>Le Client s'engage à ne faire
référence à l'accréditation de la société BUREAU ALPES CONTRÔLES que par la
reproduction intégrale des Documents techniques, qu'elle lui a adressés, et par
aucun autre moyen.</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:normal;text-autospace:none'><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>Les informations obtenues ou
générées au cours des interventions ne sont pas diffusées à une tierce personne
sauf dans les cas suivants :</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-left:18.0pt;margin-bottom:
.0001pt;text-align:justify;text-indent:-18.0pt;line-height:normal;text-autospace:
none'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>sur
autorisation formelle du Client ;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-left:18.0pt;margin-bottom:
.0001pt;text-align:justify;text-indent:-18.0pt;line-height:normal;text-autospace:
none'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>sur
demande formelle justifiée des autorités concernées lorsque les activités
entrent dans le cadre d'un agrément, d'une accréditation, d'une notification
européenne, d'une procédure judiciaire ou d'un acte d'instruction.</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:normal;text-autospace:none'><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:
justify;line-height:normal;text-autospace:none'><span style='font-size:12.0pt;
font-family:"Arial",sans-serif;color:black'>Le Client autorise d'ores et déjà
tout auditeur, dont les évaluateurs du COFRAC, opérant dans le cadre de la
délivrance d'une accréditation, à accéder à ces Documents et à assister sur
site à la réalisation des missions par la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Sauf avis
contraire, le Client autorise d'ores et déjà la société BUREAU ALPES CONTRÔLES
à faire état des missions confiées (nature, nom du donneur d'ordre, ordre de
grandeur de leurs montants), que ce soit à titre commercial ou dans ses listes
de références.</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637652"></a><a name="_Toc126835549"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>7.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Garantie-
Responsabilité</span></b></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637653"></a><a
                name="_Toc126835550"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>7.1
Garanties - Responsabilité de la société BUREAU ALPES CONTRÔLES</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L’Espace
Client est mis à disposition de l'Utilisateur par la société BUREAU ALPES
CONTRÔLES en l'état. Par conséquent, la société BUREAU ALPES CONTRÔLES ne
concède aucune garantie d'aucune sorte et ne garantit pas notamment que :</span></p>

        <ul type=disc>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>L’Espace Client
     fonctionnera sans interruption, dans les délais impartis ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>L’accès à l’Espace
     Client ne sera pas interrompu et qu'il est exempt d'erreur ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>L’Espace Client
     correspond aux exigences et/ou aux attentes de l'Utilisateur.</span></li>
        </ul>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES s'engage à mettre en œuvre tous les moyens nécessaires
pour assurer la bonne sécurité de l’Espace Client, conformément aux règles de
l'art et au titre d'une obligation de moyens. A ce titre, l’accès à l’Espace
Client se déroule dans un environnement sécurisé (protocole HTTPS) et est
protégé par un cryptage (protocole TLS) afin d’assurer la confidentialité et la
sécurité en ligne. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Aucune
information qui pourrait émaner de la société BUREAU ALPES CONTRÔLES dans le
cadre de l'utilisation de l’Espace Client par l'Utilisateur, ne saurait être
considérée comme l'octroi d'une garantie.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES ne saurait être tenue responsable de dommages directs ou
indirects, pertes ou frais de quelque nature que ce soit, résultant de
l'utilisation de l’Espace Client, ou de l'impossibilité de l'utiliser, ou d'un
mauvais fonctionnement, d'une interruption pour cause de maintenance, de
défaillance technique ou lié à une interruption de l'accès à Internet ou pour
toute autre cause, d'un virus, ou encore d'un problème de ligne ou de système
et plus généralement de tout dommage résultant de l'utilisation de l’Espace
Client.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Par
ailleurs, la société BUREAU ALPES CONTRÔLES ne peut notamment être tenue
responsable à l'égard de l'Utilisateur :</span></p>

        <ul type=disc>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>de la violation de
     droits de tiers par les Utilisateurs ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>de l'usurpation de
     l'identité d'un Utilisateur et/ou d'un tiers par un Utilisateur ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>de l'impossibilité
     par l'Utilisateur de conserver en toute sécurité et confidentialité les
     informations communiquées dans le cadre de l'utilisation de l’Espace
     Client.</span></li>
        </ul>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637654"></a><a
                name="_Toc126835551"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>7.2
Garanties - Responsabilités de l'Utilisateur</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>7.2.0 </span></b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Tout Utilisateur
de l'Espace Client s'engage à respecter la confidentialité des Informations Confidentielles
auxquelles il aura accès dans l’ Espace Client.</span><span style='font-family:
"Arial",sans-serif;color:black'> </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>7.2.1</span></b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'> D'une
manière générale, l'Utilisateur s'engage à respecter l'ensemble des présentes
CGU ainsi que des lois et règlements applicables.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>7.2.2</span></b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>
L'Utilisateur s'engage à :</span></p>

        <ul type=disc>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>utiliser l’Espace
     Client conformément à sa destination&nbsp;;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>ne pas utiliser
     l’Espace Client à des fins illicites ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>respecter l'image
     et la réputation du propriétaire de l’Espace Client ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>ne pas usurper
     l'identité d'un tiers ou d'un autre Utilisateur et/ou d'un tiers ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>ne pas modifier ou
     altérer toute ou partie de l’Espace Client et/ou restreindre par quelque
     moyen que ce soit son utilisation ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>ne falsifier
     aucune mention ou élément de l’Espace Client ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>ne pas diffuser ou
     tenter de diffuser des virus informatiques ou tout autre élément nuisible
     par l'intermédiaire de l’Espace Client ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>respecter les
     droits des tiers et notamment à ne pas utiliser, reproduire, copier,
     distribuer, modifier, représenter ou diffuser un texte, une photographie
     ou tout autre élément ou œuvres protégés susceptibles de relever du droit
     d'auteur, du droit des marques ou de tout autre droit de propriété
     intellectuelle appartenant à des tiers ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>ne pas
     s'introduire dans le système informatique du propriétaire et/ou de
     l'hébergeur de l’Espace Client ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>ne pas altérer le
     contenu de l’Espace Client ou commettre l'une quelconque des infractions
     réprimées par les articles 323-1 à 323-7 du Code pénal français (« hacking
     »), à ne pas procéder à l'envoi en nombre de messages non sollicitées dans
     les boîtes aux lettres de la société BUREAU ALPES CONTRÔLES et/ou du
     propriétaire de l’Espace Client (« spamming »), pratique réprimée par
     l'article 226-18 du Code pénal français ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>ne pas aspirer le
     contenu de l’Espace Client, et ce notamment à l'aide de programmes
     automatisés ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>ne pas faire
     transiter via l’Espace Client, et notamment via le formulaire de contact,
     de contenu de quelle que nature que ce soit (fichier, commentaire, vidéo,
     enregistrement audio…) illégal et/ou portant atteinte aux tiers, et
     notamment de contenu injurieux, raciste, sexiste, xénophobe, pédophile…</span></li>
        </ul>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>7.2.3</span></b><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'> A défaut,
la société BUREAU ALPES CONTRÔLES se réserve le droit d'engager la
responsabilité pénale et/ou civile des Utilisateurs.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Le service
est fourni « en l’état ». La société BUREAU ALPES CONTRÔLES n’est pas
responsable de la compatibilité du service «&nbsp;Espace Client Alpes
Contrôles&nbsp;» avec l’environnement technique du terminal par lequel
l’Utilisateur se connecte à l’Espace Client. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES met en œuvre les moyens d’assurer une accessibilité
optimale avec les principaux navigateurs internet du marché, en revanche, elle
ne garantit pas que l’Espace Client sera ininterrompu, opportun, sûr ou
dépourvu de toute erreur, omissions, inexactitudes ou autres ambivalences.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Plus
précisément, tous contenus téléchargés ou obtenus de toute autre manière lors
de l’utilisation de l’Espace Client et toutes conséquences directes ou
indirectes de cette utilisation sont sous la responsabilité de l’Utilisateur. </span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637655"></a><a name="_Toc126835552"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>8.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Protection des
données à caractère personnel</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES met en œuvre la politique de protection des données à
caractère personnel (ci-après les « Données ») régit par sa politique de
confidentialité&nbsp;disponible sous le lien : </span><a
                href="https://www.alpes-controles.fr/politique-de-confidentialite/"><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif'>https://www.alpes-controles.fr/politique-de-confidentialite/</span></a>
        </p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Dans le
cadre de l’Espace Client, les dispositions complémentaires suivantes
s’appliqueront&nbsp;:</span></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637656"></a><a
                name="_Toc126835553"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>8.1
Principes généraux</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES est le responsable de traitement des Données des
Utilisateurs.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Pour
accéder aux fonctionnalités et aux services de l'Espace Client, l'Utilisateur
doit créer un Compte Utilisateur tel que précisé à l'article 4 des présentes
CGU.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La création
de ce Compte entraîne la collecte et le traitement des Données suivantes :</span></p>

        <ul type=disc>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>Adresse e-mail ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>Nom ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>Prénom ;</span></li>
            <li class=MsoNormal style='color:black;text-align:justify;line-height:normal'><span
                    style='font-size:12.0pt;font-family:"Arial",sans-serif'>Téléphone.</span></li>
        </ul>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Des Données
peuvent également être recueillies par le biais des Documents créés et signés
électroniquement par les Utilisateurs concernés.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>De manière
générale, les Données qui sont communiquées par l'Utilisateur à la société
BUREAU ALPES CONTRÔLES sont destinées au personnel habilité de la société
BUREAU ALPES CONTRÔLES qui est le responsable de traitement.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les Données
pourront également être communiquées à des prestataires de la société BUREAU
ALPES CONTRÔLES sous la responsabilité de cette dernière. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Aucune
donnée n'est envoyée hors de l'Union Européenne.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES s'engage à protéger la vie privée de l'Utilisateur en
assurant la protection, la confidentialité, la non-altération, la disponibilité
et la sécurité des Données confiées.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>La société
BUREAU ALPES CONTRÔLES s'engage à respecter le principe de minimisation de la
collecte des Données.</span></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637657"></a><a
                name="_Toc126835554"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>8.2
Finalités et bases juridiques des Données</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les Données
de l'Utilisateur sont utilisées pour le traitement et le suivi du
fonctionnement de l'Espace Client : les bases juridiques de ce traitement sont
l'exécution du Contrat entre la société BUREAU ALPES CONTRÔLES et l'Utilisateur
et la nécessité aux fins de fourniture des services compris dans l'Espace
Client.</span></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637658"></a><a
                name="_Toc126835555"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>8.3
Durée de conservation des Données</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les Données
recueillies par la société BUREAU ALPES CONTRÔLES sont conservées pendant le
temps nécessaire au traitement et au suivi de l'Utilisateur.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Certaines
Données de l'Utilisateur sont également conservées par la société BUREAU ALPES
CONTRÔLES dans les délais qui lui sont imposées par la législation afin de
répondre à ses obligations légales.</span></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637659"></a><a
                name="_Toc126835556"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>8.4
Politique de Cookies</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les cookies
sont des données stockées dans l’équipement terminal d’un Internaute et
utilisées par un site Internet pour envoyer des informations au navigateur de
l’Internaute, et permettant à ce navigateur de renvoyer des informations au
site d’origine (par exemple un identifiant de session, le choix d’une langue ou
une date). L’Utilisateur est informé que, lors de ces visites sur l’Espace
Client, des cookies (témoins de connexion) peuvent être installés sur son
logiciel de navigation. Pour plus de détails, il convient de se référer à
l’article «&nbsp;4.Cookies&nbsp;» des Mentions légales.&nbsp;» de la société
BUREAU ALPES CONTRÔLES disponible&nbsp;sous le lien suivant&nbsp;:
https://www.alpes-controles.fr/mentions-legales/</span></p>

        <p class=MsoNormal style='line-height:normal'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

        <p class=MsoNormal style='line-height:normal'><a name="_Toc149637660"></a><a
                name="_Toc126835557"><b><span style='font-size:18.0pt;font-family:"Times New Roman",serif'>8.5
Droits d'opposition, de rectification, de limitation et de suppression des
Données par l'Utilisateur</span></b></a></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>En
application de la loi du 6 janvier 1978 relative à l'informatique, aux fichiers
et aux libertés modifiée et du Règlement européen n&#7506; 2016/679, dit
règlement général sur la protection des données (RGPD), l'Utilisateur dispose
des droits :</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>d'accès&nbsp;:
peut demander directement à la société BUREAU ALPES CONTRÔLES, si elle détient
des informations sur lui et demander à ce que lui soit communiquée la liste des
données,</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>de
rectification&nbsp;: peut demander la rectification des informations inexactes
le concernant. Le droit de rectification complète le droit d’accès,</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>d’oubli&nbsp;:
peut demander l’effacement des informations le concernant, pour un motif prévu
par la Règlementation,</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>de
limitation, pour des motifs légitimes&nbsp;: peut obtenir la limitation du
traitement de ses données, pour un motif prévu par la Règlementation,</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>d'opposition,
pour des motifs légitimes, à ce que les données le concernant soient traitées,
diffusées, transmises, conservées ou hébergées.</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:Symbol;
color:black'>·</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>de
portabilité&nbsp;: peut demander à recevoir les données qu’il a fournis à la
société BUREAU ALPES CONTRÔLES, ou demander à la société BUREAU ALPES CONTRÔLES
qu’elles soient transmises à un autre responsable de traitement pour un motif
prévu par la Règlementation, relativement à l'ensemble des Données le
concernant.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L'Utilisateur
dispose également du droit de formuler des directives spécifiques ou générales
concernant la conservation, l'effacement et la communication de ses Données
post mortem.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Conformément
aux dispositions légales et réglementaires, l'exercice de ces droits requiert
la justification de l'identité de l'Utilisateur.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>L'Utilisateur
peut exercer ses droits en s'adressant à la société BUREAU ALPES CONTRÔLES, par
tous moyens et notamment en adressant sa demande, accompagnée d'un titre
d'identité signé :</span></p>

        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>par
e-mail, à l'adresse suivante : </span><a href="mailto:dpo@alpes-controles.fr"
                                         target="_blank"><span style='font-size:12.0pt;font-family:"Arial",sans-serif;
color:black'>dpo&#64;alpes-controles.fr</span></a></p>

        <p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:12.0pt;font-family:Symbol'>·</span><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>par
courrier au siège social de la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Pour
faciliter les démarches et notamment en accélérer le délai de traitement, la
société BUREAU ALPES CONTRÔLES invite chaque personne concernée, lors de
l’envoi d’une demande d’exercice des droits, à :</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:"Sylfaen",serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Indiquer
quel(s) droit(s) elle souhaite exercer,</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:"Sylfaen",serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Mentionner
clairement ses noms / prénoms / coordonnées auxquelles elle souhaite recevoir
la réponse,</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal'><span style='font-size:10.0pt;font-family:"Sylfaen",serif;
color:black'>-</span><span style='font-size:7.0pt;font-family:"Times New Roman",serif;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Joindre la
copie d’une pièce d’identité.</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637661"></a><a name="_Toc126835558"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>9.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp;&nbsp;&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Divers</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Si une ou
plusieurs stipulations des CGU sont tenues pour non valides ou déclarées comme
telles en application d'une loi, d'un règlement ou à la suite d'une décision
définitive d'une juridiction compétente, les autres stipulations garderont
toute leur force et toute leur portée. La clause sera déclarée nulle et non
valide et sera remplacée par une clause qui se rapprochera le plus quant à son
contenu de la clause initialement annulée.</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;line-height:normal'><a
                name="_Toc149637662"></a><a name="_Toc126835559"><b><span style='font-size:
24.0pt;font-family:"Times New Roman",serif'>10.</span></b></a><b><span
                style='font-size:7.0pt;font-family:"Times New Roman",serif'>&nbsp; </span></b><b><span
                style='font-size:24.0pt;font-family:"Times New Roman",serif'>Droit applicable
et juridiction compétente</span></b></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>Les
présentes CGU et les opérations qui en découlent sont régies et soumis au droit
français. Les présentes CGU sont rédigées en langue française. Dans le cas où
elles seraient traduites en une ou plusieurs langues étrangères, seul la
version française fait foi entre les parties en cas de litige et pourra être
produite en justice. </span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>En cas de
différend portant sur la validité, l'interprétation, l'exécution ou, plus
généralement se rapportant ou ayant un lien quelconque avec les présentes CGU,
les parties conviennent, avant toute procédure judiciaire, sauf conservatoire,
de se rapprocher et de rechercher un règlement amiable au différend.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>En cas de
litige, et à défaut de règlement amiable, tout différend pouvant naître entre
les parties sera soumis, à la compétence des juridictions dans le ressort
desquels se trouve le siège social de la société BUREAU ALPES CONTRÔLES.</span></p>

        <p class=MsoNormal style='text-align:justify;line-height:normal'><span
                style='font-size:12.0pt;font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

    </div>
</div>
