import { selectAuthControlledUser } from './../auth/auth.selectors';
import { selectAuthState } from './../core.state';
import { api } from './../api/api.vars';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { tap, flatMap, take, catchError, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../core.module';
import { selectAuthToken } from '../auth/auth.selectors';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { authLogout, authRefreshTokenSuccess } from '../auth/auth.actions';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  public static UNAUTHORIZED_CODE = 401;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(selectAuthToken).pipe(
      withLatestFrom(this.store.select(selectAuthControlledUser)),
      take(1),
      mergeMap(([data, controlledUser]) => {

        if (
          !!data &&
          !!data.access_token &&
          !request.headers.has('Authorization')
        ) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${data.access_token}`,
              'x-ac-userid': !!controlledUser && !!controlledUser.id ? `${controlledUser.id}` : ''
            }
          });
        }
        return next.handle(request).pipe(
          catchError(err => {
            if (err.status === HttpAuthInterceptor.UNAUTHORIZED_CODE) {
              console.log('Refreshing token...');
              // Make sure it's not login requests or refresh request
              if (!!data && !!data.access_token && !!err.url && err.url.search(api.endpoints.auth.LOGIN) === -1 && err.url.search(api.endpoints.auth.REFRESH_TOKEN) === -1) {
                // Retrieve refresh token
                return this.authService
                  .retrieveRefreshToken({ token: data.access_token })
                  .pipe(
                    tap({
                      next: tokenData => {
                        this.store.dispatch(
                          authRefreshTokenSuccess({ authToken: tokenData.data })
                        );
                      },
                      error: er => {
                        this.store.dispatch(authLogout());
                      }
                    }),
                    mergeMap(d => {
                      request = request.clone({
                        setHeaders: {
                          Authorization: `Bearer ${d.data.access_token}`
                        }
                      });
                      return next.handle(request);
                    }),
                    catchError(e => {
                      return throwError(e);
                    })
                  )
              }
            }
            return throwError(err);
          })
        );
      })
    );
  }
}
